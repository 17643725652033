<template>
  <div class="customer">
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="text-md-center"
      >
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="text-none"
              v-bind="attrs"
              outlined
              :disabled="isSmsCreditBalanceLoading"
              @click="getCreditBalance"
              v-on="on"
            >
              <v-icon
                left
                size="22"
                :class="{ rotating: isSmsCreditBalanceLoading}"
              >
                {{ icons.mdiCached }}
              </v-icon>
              Credit balance: <strong class="ml-2">{{ smsCreditBalance | formatCreditBalance }}</strong> ({{ smsThatCanBeSentWithAvailableCreditBalance }} SMS)
            </v-btn>
          </template>
          <span>Click to refresh</span>
        </v-tooltip>
      </v-col>
      <v-col
        class="text-right"
        sm="12"
        md="2"
      >
        <v-btn
          color="primary"
          class="mb-5"
          outlined
          @click="showTopUpDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiCashPlus }}
          </v-icon>
          Top up
        </v-btn>
      </v-col>
      <v-col
        class="text-right"
        sm="12"
        md="2"
      >
        <v-btn
          v-if="can('sms-create')"
          color="primary"
          class="mb-5"
          @click="showSendSmsDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiChatPlusOutline }}
          </v-icon>
          Send Sms
        </v-btn>
      </v-col>
    </v-row>
    <sms-table
      :sms-url="smsUrl"
      :send-sms-dialog="showSendSmsDialog"
      @sms-sent="getCreditBalance"
      @close="showSendSmsDialog = false"
    />
    <v-dialog
      v-model="showTopUpDialog"
      max-width="650px"
      class="mx-auto my-12"
    >
      <v-card>
        <v-card-title>
          Top up SMS credit
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <p>On M-pesa menu go to 'Lipa na M-pesa' then select 'Pay Bill'</p>
          <p>Enter Business Number <strong>{{ paybill_number }}</strong></p>
          <p>Enter Account Number <strong>{{ account_number }}</strong></p>
          <p>Enter the Amount of credits you want to buy</p>
          <p>Confirm that all the details are correct and press Ok</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="showTopUpDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  mdiChatPlusOutline, mdiCached, mdiCashPlus,
} from '@mdi/js'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SmsTable from '@/components/tables/SmsTable.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    breadcrumb: BreadCrumb,
    SmsTable,
  },
  filters: {
    formatCreditBalance(value) {
      const balance = value.split(/ (.*)/)

      // If the split doesn't result in at least two parts, return the value as-is
      if (balance.length < 2) {
        return value
      }

      const balanceCurrency = balance[0]
      const balanceValue = balance[1]

      return `${balanceCurrency} ${Number(balanceValue).toFixed(2)}`
    },
  },
  mixins: [hasPermission],
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'SMS',
          disabled: true,
          to: { name: 'sms' },
        },
      ],
      paybill_number: process.env.VUE_APP_SMS_PAYBILL_NUMBER,
      account_number: process.env.VUE_APP_SMS_ACCOUNT_NUMBER,
      isSmsCreditBalanceLoading: true,
      smsCreditBalance: '',
      smsThatCanBeSentWithAvailableCreditBalance: '',
      showSendSmsDialog: false,
      showTopUpDialog: false,
      icons: {
        mdiChatPlusOutline,
        mdiCached,
        mdiCashPlus,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    smsUrl() {
      return `sms?stationId=${this.selectedStation}`
    },
  },
  mounted() {
    this.getCreditBalance()
  },
  methods: {
    getCreditBalance() {
      this.isSmsCreditBalanceLoading = true
      axios
        .get(
          'sms/credit-balance',
        )
        .then(response => {
          this.isSmsCreditBalanceLoading = false
          this.smsCreditBalance = response.data.balance
          this.smsThatCanBeSentWithAvailableCreditBalance = response.data.sms
        })
        .catch(error => {
          this.isSmsCreditBalanceLoading = false
          this.$toast.error(error.response.data.message)
        })
    },
  },
}
</script>
